import { gql, useMutation, useQuery } from "@apollo/client";
import { Order, OrderStatus } from "@arowana/graphql";
import { usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TablePagination,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import useExportResource from "apps/supplier/src/hooks/useExportResource";
import sort from "fast-sort";
import { get } from "lodash";
import moment from "moment-timezone";
import qs from "qs";
import { useContext, useMemo, useState } from "react";

import ExportMenu from "../../../../components/ExportMenu";
import Loader from "../../../../components/Loader";
import PageHeader from "../../../../components/PageHeader";
import Routes from "../../../../Constants/Routes";
import PrintPageButton from "../../../../utils/PrintPageButton";
import { FlagsmithContext } from "../../../context/FlagsmithContext";
import { MenuLink } from "../../../orders/pages/OrderList";
import BulkFulFillOrders from "../../components/BulkFulFillOrders";
import OperationsFilters from "../../components/OperationsFilters";
import OrderSignatureModal from "../../components/OrderSignatureModal";
import OrderItem from "./OrderItem";

const EXPORT_ORDERS = gql`
  mutation ExportOrdersPicklist(
    $sort: OrdersExportSortInput!
    $filter: OrdersExportFilterInput!
    $format: OrdersExportFormat!
  ) {
    url: exportSupplierOrders(filter: $filter, sort: [$sort], format: $format)
  }
`;

const useStyles = makeStyles(theme => ({
  export: { marginLeft: theme.spacing(1) },
  pagination: {
    height: theme.spacing(4),
    minHeight: theme.spacing(4),
    paddingLeft: 0,
  },
  paginationLoading: {
    borderRadius: theme.shape.borderRadius,
    height: theme.spacing(4),
    width: theme.spacing(20),
  },
  paginationRoot: {
    border: "none",
    marginLeft: theme.spacing(-3),
  },
}));

const ORDERS_PICKLIST = gql`
  query OrdersPicklist($page: PaginationInput, $filter: OrdersFilterInput) {
    currentSupplier {
      id
      orders(page: $page, filter: $filter) {
        edges {
          cursor
          node {
            id
            account {
              id
              phone
            }
            address {
              formattedAddress
              notes
            }
            audience
            clientId
            fees {
              amount
            }
            fulfilled
            fulfillmentDate
            fulfillmentInstructions
            fulfillmentMethod
            fulfillmentTime {
              label
            }
            invoiceNumber
            lineItems {
              id
              cases
              caseSize
              pricePerUnit
              productId
              productName
              quantity
              quantityOrdered
              sku
              subName
              variantId
              unit
            }
            locationId
            notes
            payeeEmail
            payeeName
            paymentCollectionMethod
            preAuthorization {
              amount
            }
            refunds {
              amount
            }
            status
            total
            trackingCode
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const defaultNaturalComparer = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });

export type Location = {
  id: string;
  name: string;
};

export type PicklistProps = {
  audience: string | qs.ParsedQs | string[] | qs.ParsedQs[];
  endDate: string | qs.ParsedQs | string[] | qs.ParsedQs[];
  fulfilled?: string | qs.ParsedQs | string[] | qs.ParsedQs[];
  locationIds: string[] | qs.ParsedQs[];
  locations: Location[];
  onAudienceChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onFilterByFulfilledChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onEndChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onStartChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onZoneChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  startDate: string | qs.ParsedQs | string[] | qs.ParsedQs[];
  timezone: string;
};

const ROW_PER_PAGE = 100;

const OrdersPicklist = ({
  audience,
  endDate,
  fulfilled,
  locationIds,
  locations,
  onAudienceChange,
  onEndChange,
  onFilterByFulfilledChange,
  onStartChange,
  onLocationChange,
  startDate,
  timezone,
}: PicklistProps) => {
  const classes = useStyles();
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROW_PER_PAGE);

  const parsedStart = moment.tz(startDate, timezone).startOf("day").utc();
  const parsedEnd = moment.tz(endDate, timezone).endOf("day").utc();

  const filter = {
    after: moment.tz(startDate, timezone).startOf("day").utc().format(),
    ...(audience !== "ALL" && { audience }),
    before: moment.tz(endDate, timezone).endOf("day").utc().format(),
    ...(fulfilled !== "ALL" && { fulfilled: fulfilled === "true" }),
    locationIds,
    statuses: [
      OrderStatus.completed,
      OrderStatus.delivered,
      OrderStatus.in_transit,
      OrderStatus.paid,
      OrderStatus.payment_failed,
      OrderStatus.submitted,
    ], // must be lowercased with underscore
  };

  const flagsmith = useContext(FlagsmithContext);
  const hasBagLabels = flagsmith.hasFeature("supplier-order-bag-labels");
  const hasDailySeafoodExport = flagsmith.hasFeature("dailyseafood-xlsx");
  const hasRoutificExport = flagsmith.hasFeature("routific-xlsx");
  const hasWater2tableXlsx = flagsmith.hasFeature("water2table-xlsx");

  const { data, loading } = useQuery(ORDERS_PICKLIST, {
    context: { source: DATALAYER },
    fetchPolicy: "network-only",
    onCompleted: response => {
      setPageInfo(response?.currentSupplier?.orders?.pageInfo);
    },
    onError: resetPagination,
    skip: !parsedStart.isValid() || !parsedEnd.isValid(),
    variables: {
      filter,
      page: pagination,
    },
  });
  const totalCount = data?.currentSupplier?.orders?.pageInfo?.totalCount ?? 0;
  const orders = data?.currentSupplier?.orders?.edges?.map(({ node }) => node) ?? [];
  const [sortField, setSortField] = useState("payeeName");
  const onSortFieldChange = event => {
    resetPagination();
    setSortField(event.target.value);
  };
  const sortedOrders = useMemo(
    () =>
      sort([...orders]).by([
        {
          asc: order => get(order, sortField),
          comparer: defaultNaturalComparer.compare,
        },
        { asc: "invoiceNumber" },
      ]),
    [orders, sortField],
  );
  const fulfillableOrderIds = useMemo(
    () =>
      orders
        ? [...orders]
            .filter(order => order.status !== OrderStatus.paid && order.fulfilled === false)
            .map(order => order.id)
        : [],
    [orders],
  );

  const locationsById = useMemo(
    () =>
      locations?.reduce(
        (merged, location) => ({
          ...merged,
          [location.id]: location,
        }),
        {},
      ),
    [locations],
  );
  const exportLink = `${Routes.ORDERS_PICKLIST_EXPORT}?from=${startDate}&to=${endDate}${
    audience !== "ALL" ? `&audience=${audience}` : ""
  }${fulfilled !== "ALL" ? `&fulfilled=${fulfilled}` : ""}`;

  const { ExportDownloadBanner, loadingExport, onExportClick } = useExportResource(EXPORT_ORDERS, {
    filter,
    format: "DEFAULT_XLSX",
    sort: { field: "FULFILLMENT_DATE", order: -1 },
  });

  const {
    ExportDownloadBanner: QboDownloadBanner,
    loadingExport: loadingQboExport,
    onExportClick: onQboExportClick,
  } = useExportResource(
    EXPORT_ORDERS,
    { filter, format: "QBO_CSV", sort: { field: "FULFILLMENT_DATE", order: -1 } },
    "QBO export is ready!",
  );

  const {
    ExportDownloadBanner: BagLabelsDownloadBanner,
    loadingExport: loadingBagLabelsExport,
    onExportClick: onBagLabelsExportClick,
  } = useExportResource(
    EXPORT_ORDERS,
    { filter, format: "BAG_LABELS", sort: { field: "FULFILLMENT_DATE", order: -1 } },
    "Order bag labels are ready!",
  );

  const {
    ExportDownloadBanner: DailySeafoodDownloadBanner,
    loadingExport: loadingDailySeafoodExport,
    onExportClick: onDailySeafoodExportClick,
  } = useExportResource(
    EXPORT_ORDERS,
    { filter, format: "DAILYSEAFOOD_XLSX", sort: { field: "FULFILLMENT_DATE", order: -1 } },
    "Daily Seafood export is ready!",
  );

  const {
    ExportDownloadBanner: RoutificDownloadBanner,
    loadingExport: loadingRoutificExport,
    onExportClick: onRoutificExportClick,
  } = useExportResource(
    EXPORT_ORDERS,
    { filter, format: "ROUTIFIC_XLSX", sort: { field: "FULFILLMENT_DATE", order: -1 } },
    "Routific export is ready!",
  );

  const {
    ExportDownloadBanner: Water2TableDownloadBanner,
    loadingExport: loadingWater2TableExport,
    onExportClick: onWater2TableExportClick,
  } = useExportResource(EXPORT_ORDERS, { filter, format: "WATER2TABLE_XLSX", sort }, "Water2Table export is ready!");

  const handlePageChange = (_, newPage: number) => {
    onPageChange({ page: newPage });
  };
  const handleFilterChange = <T,>(value: T, func: (value: T) => void) => {
    resetPagination();
    func(value);
  };

  // Signature Modal
  const [orderToSign, setOrderToSign] = useState(null);

  return (
    <Box>
      {loading && <Loader />}
      <PageHeader stickyHeader title="Order picklist">
        <Grid alignItems="flex-end" container justifyContent="space-between">
          <Grid item>
            {loading ? (
              <Skeleton className={classes.paginationLoading} variant="rect" />
            ) : (
              <TablePagination
                classes={{ root: classes.paginationRoot, toolbar: classes.pagination }}
                count={totalCount}
                labelRowsPerPage=""
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={ROW_PER_PAGE}
                rowsPerPageOptions={[]}
              />
            )}
          </Grid>
          <Grid item>
            <PrintPageButton color="primary">Print</PrintPageButton>
            {hasBagLabels && (
              <Button
                className={classes.export}
                color="primary"
                disabled={loadingBagLabelsExport}
                endIcon={loadingBagLabelsExport && <CircularProgress size={16} />}
                onClick={onBagLabelsExportClick()}
                variant="outlined"
              >
                Print Bag Labels
              </Button>
            )}
            <ExportMenu
              buttonProps={{
                className: classes.export,
              }}
              disabled={sortedOrders.length === 0}
              loading={loadingExport || loadingQboExport}
              menuId="orders-picklist-export-menu"
              renderMenu={onMenuClose => [
                <MenuItem onClick={onExportClick(onMenuClose)}>To Excel (.xlsx)</MenuItem>,
                <MenuItem onClick={onQboExportClick(onMenuClose)}>To QBO (.csv)</MenuItem>,
                hasDailySeafoodExport && (
                  <MenuItem onClick={onDailySeafoodExportClick(onMenuClose)}>To Daily Seafood export (.xlsx)</MenuItem>
                ),
                hasRoutificExport && (
                  <MenuItem onClick={onRoutificExportClick(onMenuClose)}>To Routific export (.xlsx)</MenuItem>
                ),
                hasWater2tableXlsx ? (
                  <MenuItem onClick={onWater2TableExportClick(onMenuClose)}>To Water2Table (.xlsx)</MenuItem>
                ) : null,
                <MenuItem component={MenuLink} to={exportLink}>
                  Custom Export
                </MenuItem>,
              ]}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          {ExportDownloadBanner}
          {QboDownloadBanner}
          {BagLabelsDownloadBanner}
          {DailySeafoodDownloadBanner}
          {RoutificDownloadBanner}
          {Water2TableDownloadBanner}
        </Box>
      </PageHeader>
      <OperationsFilters
        audience={audience}
        bulkControls={<BulkFulFillOrders fulfillableOrderIds={fulfillableOrderIds} />}
        endDate={endDate}
        extraControls={[
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="sort-by">Sort by</InputLabel>
            <Select
              inputProps={{
                id: "sort-by",
              }}
              label="Sort by"
              onChange={onSortFieldChange}
              value={sortField}
              variant="outlined"
            >
              <MenuItem value="payeeName">Customer Name</MenuItem>
              <MenuItem value="fulfillmentMethod">Order Type</MenuItem>
              <MenuItem value="fulfilled">Fulfilled</MenuItem>
            </Select>
          </FormControl>,
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="filter-by">Filter by</InputLabel>
            <Select
              inputProps={{
                id: "filter-by",
              }}
              label="Filter by"
              onChange={e => handleFilterChange(e, onFilterByFulfilledChange)}
              value={fulfilled}
              variant="outlined"
            >
              <MenuItem value="true">Fulfilled</MenuItem>
              <MenuItem value="false">Unfulfilled</MenuItem>
              <MenuItem value="ALL">All</MenuItem>
            </Select>
          </FormControl>,
        ]}
        locationIds={locationIds}
        locations={locations}
        onAudienceChange={e => handleFilterChange(e, onAudienceChange)}
        onEndChange={e => handleFilterChange(e, onEndChange)}
        onStartChange={e => handleFilterChange(e, onStartChange)}
        onLocationChange={locationIds => {
          handleFilterChange(locationIds, onLocationChange);
        }}
        startDate={startDate}
      />
      <main role="main">
        {sortedOrders.length
          ? sortedOrders.map(order => (
              <OrderItem
                key={order.id}
                order={order}
                timezone={timezone}
                location={locationsById[order.locationId]}
                onRequestSignature={() => setOrderToSign(order)}
              />
            ))
          : !loading && <Typography>No orders found.</Typography>}
      </main>
      <OrderSignatureModal order={orderToSign} onClose={() => setOrderToSign(null)} />
    </Box>
  );
};

export default OrdersPicklist;
