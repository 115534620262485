import { gql, useQuery } from "@apollo/client";
import {
  FEATURE_SUPPLIER_CREATE_WHOLESALE_ORDER,
  FEATURE_SUPPLIER_EXPORT_ORDERS_MINOTAUR,
  FEATURE_SUPPLIER_EXPORT_ORDERS_SEASOFT_TSV,
} from "@arowana/flags";
import { OrderAudience } from "@arowana/graphql";
import { Dollar, PaginatedTable, QuickSearch, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { GridSortDirection } from "@material-ui/data-grid";
import { Close, Search as SearchIcon } from "@material-ui/icons";
import moment from "moment-timezone";
import pluralize from "pluralize";
import qs from "qs";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { useDebounce } from "use-debounce";

import ConfirmationModal from "../../../components/ConfirmationModal";
import ExportMenu from "../../../components/ExportMenu";
import FulfillmentChip from "../../../components/FulfillmentChip";
import PageHeader from "../../../components/PageHeader";
import StatusChip from "../../../components/StatusChip";
import Routes from "../../../Constants/Routes";
import useExportResource from "../../../hooks/useExportResource";
import { AUDIENCE_DISPLAY } from "../../../utils/audienceDisplay";
import { AccountContext } from "../../context/AccountContext";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import Audiences from "../constants/orderAudience";
import Statuses from "../constants/orderStatus";

const ORDERS = gql`
  query Orders($page: PaginationInput, $sort: OrdersSortInput, $filter: OrdersFilterInput) {
    currentSupplier {
      id
      orders(page: $page, sort: $sort, filter: $filter) {
        edges {
          cursor
          node {
            id
            accountId
            account {
              id
            }
            audience
            clientId
            createdAt
            fulfillmentDate
            fulfillmentMethod
            invoiceNumber
            lineItems {
              id
              pricePerUnit
              productName
              quantity
              subName
            }
            payeeEmail
            payeeName
            status
            subTotal
            total
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const EXPORT_ORDERS = gql`
  mutation ExportOrders(
    $sort: OrdersExportSortInput!
    $filter: OrdersExportFilterInput!
    $format: OrdersExportFormat!
  ) {
    url: exportSupplierOrders(filter: $filter, sort: [$sort], format: $format)
  }
`;

const useStyles = makeStyles(theme => ({
  beta: {
    marginLeft: theme.spacing(1),
  },
  filterAudience: {
    flex: "0 1 175px",
    [theme.breakpoints.down("sm")]: {
      flex: "0 1 45%",
    },
  },
  filterEnd: {
    flex: "0 1 175px",
    [theme.breakpoints.down("sm")]: {
      flex: "0 1 45%",
    },
  },
  filterSearch: {
    flex: "4 1 200px",
    [theme.breakpoints.down("sm")]: {
      flex: "0 1 100%",
    },
  },
  filterStart: {
    flex: "0 1 175px",
    [theme.breakpoints.down("sm")]: {
      flex: "0 1 45%",
    },
  },
  filterStatus: {
    flex: "0 1 175px",
    [theme.breakpoints.down("sm")]: {
      flex: "0 1 45%",
    },
  },
  filtersContainer: {
    "& > *": {
      margin: theme.spacing(2, 0, 0, 2),
    },
    "display": "inline-flex",
    "flexFlow": "row wrap",
    "justifyContent": "flex-end",
    "margin": theme.spacing(-1, 0, 0, -2),
    "marginBottom": theme.spacing(2),
    "width": `calc(100% + ${theme.spacing(2)}px)`,
    [theme.breakpoints.down("sm")]: {
      "justify-content": "space-between",
    },
  },
  instructions: { paddingLeft: theme.spacing(2) },
  remaniningCount: {
    fontWeight: "bold",
    marginLeft: theme.spacing(0.5),
  },
  search: {
    margin: theme.spacing(1, 0),
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  searchResult: {
    width: "100%",
  },
}));

const PlusNItems = ({ n }) => {
  const classes = useStyles();

  return n > 0 && <span className={classes.remaniningCount}> + {pluralize("item", n, true)}</span>;
};

const getColumns = ({ query, timezone }) => [
  {
    field: "id",
    hide: true,
  },
  {
    field: "INVOICE_NUMBER",
    headerName: "Order #",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.ORDER_DETAILS.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    sortable: !query,
    valueGetter: param => param.row.invoiceNumber,
    width: 120,
  },
  {
    field: "account",
    headerName: "Customer",
    renderCell: param => {
      const wholesaleOrder = param.row.audience === OrderAudience.WHOLESALE;

      if (
        !param.row.payeeName ||
        !param.row.payeeEmail ||
        (wholesaleOrder && !param.row.clientId) ||
        (!wholesaleOrder && !param.value)
      ) {
        return "Unknown";
      }

      return (
        <div style={{ display: "flex", flexDirection: "column", lineHeight: "normal" }}>
          <Link
            component={RouterLink}
            target="_blank"
            to={
              wholesaleOrder
                ? Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", param.row.clientId)
                : Routes.CUSTOMER_DETAILS.replace(":id", param.value.id)
            }
          >
            {param.row.payeeName}
          </Link>
          <Link href={`mailto:${param.row.payeeEmail}`} target="_blank">
            {param.row.payeeEmail}
          </Link>
        </div>
      );
    },
    sortable: false,
    width: 250,
  },
  {
    field: "audience",
    headerName: "Audience",
    sortable: false,
    valueGetter: ({ value }) => AUDIENCE_DISPLAY[value],
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    valueGetter: param => Statuses.find(status => status.id === param.value)?.label ?? "N/A",
    width: 140,
  },
  {
    field: "lineItems",
    headerName: "Ordered items",
    renderCell: param => {
      const lineItems = param.value;

      if (!lineItems?.length) {
        return "No products";
      }

      const firstItem = lineItems[0].productName;
      const remainingCount = Math.max(lineItems.length - 1, 0);

      return (
        <>
          {firstItem}
          <PlusNItems n={remainingCount} />
        </>
      );
    },
    sortable: false,
    width: 300,
  },
  {
    field: "FULFILLMENT_DATE",
    headerName: "Fulfillment date",
    sortable: !query,
    valueGetter: param => moment.tz(param.row.fulfillmentDate, timezone).format("L"),
    width: 160,
  },
  {
    field: "total",
    headerName: "Total",
    renderCell: param => <Dollar amount={param.value} />,
    sortable: false,
    width: 150,
  },
];

const ROW_PER_PAGE = 25;

const isValidDate = dateString => moment(dateString).isValid();

const DEFAULT_SORT_STATE = {
  field: "INVOICE_NUMBER",
  sort: "desc" as GridSortDirection,
};

export const MenuLink = (props: RouterLinkProps) => <Link component={RouterLink} underline="none" {...props} />;

const CreateButton = ({ showRetail, showWholesale }: { showRetail: boolean; showWholesale: boolean }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
  const onMenuClick = useCallback(event => setMenuAnchorEl(event.currentTarget), []);
  const onMenuClose = useCallback(() => setMenuAnchorEl(null), []);

  if (showRetail && showWholesale) {
    return (
      <>
        <Button
          aria-controls="create-menu"
          aria-haspopup="true"
          color="primary"
          onClick={onMenuClick}
          variant="contained"
        >
          Create Order
        </Button>
        <Menu id="create-menu" keepMounted open={Boolean(menuAnchorEl)} onClose={onMenuClose} anchorEl={menuAnchorEl}>
          <MenuItem component={MenuLink} to={Routes.ORDER_CREATE}>
            Retail order
          </MenuItem>
          <MenuItem component={MenuLink} to={Routes.ORDER_CREATE_WHOLESALE}>
            Wholesale order
          </MenuItem>
        </Menu>
      </>
    );
  }

  if (showRetail || showWholesale) {
    return (
      <Button
        color="primary"
        component={RouterLink}
        to={showRetail ? Routes.ORDER_CREATE : Routes.ORDER_CREATE_WHOLESALE}
        variant="contained"
      >
        Create Order
      </Button>
    );
  }

  return null;
};

const OrderList = ({ history }) => {
  const classes = useStyles();
  const params = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { supplier } = useContext(AccountContext);
  const { timezone } = supplier;

  const flagsmith = useContext(FlagsmithContext);
  const hasBagLabels = flagsmith.hasFeature("supplier-order-bag-labels");
  const hasWholesale = flagsmith.hasFeature("b2b");
  const hasRetail = flagsmith.hasFeature("b2c");
  const hasMeilisearch = flagsmith.hasFeature("supplier-meilisearch");
  const hasWholesaleOrderCreate = flagsmith.hasFeature(FEATURE_SUPPLIER_CREATE_WHOLESALE_ORDER);
  const hasSeasoftExport = flagsmith.hasFeature(FEATURE_SUPPLIER_EXPORT_ORDERS_SEASOFT_TSV);
  const hasMinotaurJSON = flagsmith.hasFeature(FEATURE_SUPPLIER_EXPORT_ORDERS_MINOTAUR);
  const hasDailySeafoodXlsx = flagsmith.hasFeature("dailyseafood-xlsx");
  const hasRoutificXlsx = flagsmith.hasFeature("routific-xlsx");
  const hasWater2tableXlsx = flagsmith.hasFeature("water2table-xlsx");

  const [sortState, setSortState] = useState(DEFAULT_SORT_STATE);
  const [openInstrcuctionModal, setOpenInstrcuctionModal] = useState(false);
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROW_PER_PAGE);

  const { audience, from, query = "", status, to } = params ?? {};
  const [debouncedTo] = useDebounce(to, 300);
  const [debouncedFrom] = useDebounce(from, 300);

  const filter = {
    after: moment.tz(debouncedFrom, timezone).startOf("day").utc().format(),
    ...(audience !== "ALL" && { audience }),
    before: moment.tz(debouncedTo, timezone).endOf("day").utc().format(),
    ...(query && { query }),
    ...(status !== "all" && { statuses: [status] }), // must be lowercased with underscore
  };
  const sort = sortState
    ? {
        field: sortState.field,
        order: sortState.sort === "desc" ? -1 : 1,
      }
    : { field: DEFAULT_SORT_STATE.field, order: DEFAULT_SORT_STATE.sort === "desc" ? -1 : 1 };

  const { loading, data } = useQuery(ORDERS, {
    context: { source: DATALAYER },
    fetchPolicy: "network-only",
    onCompleted: response => {
      setPageInfo(response?.currentSupplier?.orders?.pageInfo);
    },
    // reset page to first page in case of error
    onError: resetPagination,
    skip: !status,
    variables: {
      filter,
      page: pagination,
      sort,
    },
  });

  const totalCount = data?.currentSupplier?.orders?.pageInfo?.totalCount ?? 0;
  const rows = data?.currentSupplier?.orders?.edges.map(({ node }) => node) ?? [];

  // Table sort / pagination
  const columns = useMemo(() => getColumns({ query, timezone }), [query, timezone]);
  // search does not support soritng
  const sortModel = !query && sortState ? [sortState] : [];

  const onSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  // initial load
  useEffect(() => {
    if (Object.keys(params).length === 0) {
      const query = {
        audience: "ALL",
        from: moment().format("YYYY-MM-DD"),
        status: "all",
        to: moment().add(14, "days").format("YYYY-MM-DD"),
      };
      history.replace({
        search: qs.stringify(query),
      });
    }
  });

  const updateQuery = keyValue => {
    const query = {
      ...params,
      ...keyValue,
    };

    history.replace({
      search: qs.stringify(query),
    });
    resetPagination();
  };

  const onExportComplete = () => setOpenInstrcuctionModal(true);

  // Export
  const { ExportDownloadBanner, loadingExport, onExportClick } = useExportResource(EXPORT_ORDERS, {
    filter,
    format: "DEFAULT_XLSX",
    sort,
  });

  const {
    ExportDownloadBanner: DailySeafoodDownloadBanner,
    loadingExport: loadingDailySeafoodExport,
    onExportClick: onDailySeafoodExportClick,
  } = useExportResource(EXPORT_ORDERS, { filter, format: "DAILYSEAFOOD_XLSX", sort }, "Daily Seafood export is ready!");

  const {
    ExportDownloadBanner: QboDownloadBanner,
    loadingExport: loadingQboExport,
    onExportClick: onQboExportClick,
  } = useExportResource(EXPORT_ORDERS, { filter, format: "QBO_CSV", sort }, "QBO export is ready!", onExportComplete);

  const {
    ExportDownloadBanner: SeasoftDownloadBanner,
    loadingExport: loadingSeasoftExport,
    onExportClick: onSeasoftExportClick,
  } = useExportResource(EXPORT_ORDERS, { filter, format: "SEASOFT_TSV", sort }, "Seasoft TSV export is ready!");

  const {
    ExportDownloadBanner: BagLabelsDownloadBanner,
    loadingExport: loadingBagLabelsExport,
    onExportClick: onBagLabelsExportClick,
  } = useExportResource(EXPORT_ORDERS, { filter, format: "BAG_LABELS", sort }, "Order bag labels are ready!");

  const {
    ExportDownloadBanner: MinotaurDownloadBanner,
    loadingExport: loadingMinotaurExport,
    onExportClick: onMinotaurExportClick,
  } = useExportResource(EXPORT_ORDERS, { filter, format: "MINOTAUR_JSON", sort }, "Minotaur JSON is ready!");

  const {
    ExportDownloadBanner: RoutificDownloadBanner,
    loadingExport: loadingRoutificExport,
    onExportClick: onRoutificExportClick,
  } = useExportResource(EXPORT_ORDERS, { filter, format: "ROUTIFIC_XLSX", sort }, "Routific export is ready!");

  const {
    ExportDownloadBanner: Water2TableDownloadBanner,
    loadingExport: loadingWater2TableExport,
    onExportClick: onWater2TableExportClick,
  } = useExportResource(EXPORT_ORDERS, { filter, format: "WATER2TABLE_XLSX", sort }, "Water2Table export is ready!");

  // Filters
  const onAudienceChange = event => updateQuery({ audience: event.target.value });
  const onStatusChange = event => updateQuery({ status: event.target.value });
  const onDateChange = key => event => {
    const dateString = event.target.value;

    if (isValidDate(dateString)) {
      updateQuery({ [key]: dateString });
    }
  };

  const [searchInput, setSearchInput] = useState(query);
  const onSearchInputChange = event => setSearchInput(event.target.value);
  const onSearchSubmit = ({ type, key }) => {
    if (type === "keydown" && key === "Enter") {
      updateQuery({ query: searchInput });
    }
  };
  const onSearchClear = () => {
    setSearchInput("");
    updateQuery({ query: undefined });
  };

  const exportLink = `${Routes.ORDERS_EXPORT}?from=${from}&to=${to}&status=${String(status).toUpperCase()}${
    audience !== "ALL" ? `&audience=${audience}` : ""
  }`;

  return (
    <>
      <PageHeader
        primaryActions={
          <>
            {hasBagLabels && (
              <Button
                color="primary"
                disabled={loadingBagLabelsExport}
                endIcon={loadingBagLabelsExport && <CircularProgress size={16} />}
                onClick={onBagLabelsExportClick()}
                variant="outlined"
              >
                Print Bag Labels
              </Button>
            )}
            <ExportMenu
              disabled={rows.length === 0}
              loading={
                loadingExport ||
                loadingQboExport ||
                loadingSeasoftExport ||
                loadingMinotaurExport ||
                loadingDailySeafoodExport ||
                loadingRoutificExport
              }
              menuId="orders-export-menu"
              renderMenu={onMenuClose => [
                <MenuItem onClick={onExportClick(onMenuClose)}>To Excel (.xlsx)</MenuItem>,
                <MenuItem onClick={onQboExportClick(onMenuClose)}>To QBO (.csv)</MenuItem>,
                hasDailySeafoodXlsx ? (
                  <MenuItem onClick={onDailySeafoodExportClick(onMenuClose)}>To Daily Seafood (.xlsx)</MenuItem>
                ) : null,
                hasMinotaurJSON ? (
                  <MenuItem onClick={onMinotaurExportClick(onMenuClose)}>To Minotaur (.json)</MenuItem>
                ) : null,
                hasSeasoftExport ? (
                  <MenuItem onClick={onSeasoftExportClick(onMenuClose)}>
                    To Seasoft (.tsv) <Chip className={classes.beta} size="small" label="BETA" />
                  </MenuItem>
                ) : null,
                hasRoutificXlsx ? (
                  <MenuItem onClick={onRoutificExportClick(onMenuClose)}>To Routific (.xlsx)</MenuItem>
                ) : null,
                hasWater2tableXlsx ? (
                  <MenuItem onClick={onWater2TableExportClick(onMenuClose)}>To Water2Table (.xlsx)</MenuItem>
                ) : null,
                <MenuItem component={MenuLink} to={exportLink}>
                  Custom Export
                </MenuItem>,
              ]}
            />
            <CreateButton showRetail={hasRetail} showWholesale={hasWholesale && hasWholesaleOrderCreate} />
          </>
        }
        stickyHeader
        title="Orders"
      />

      {DailySeafoodDownloadBanner}
      {BagLabelsDownloadBanner}
      {ExportDownloadBanner}
      {MinotaurDownloadBanner}
      {QboDownloadBanner}
      {SeasoftDownloadBanner}
      {RoutificDownloadBanner}
      {Water2TableDownloadBanner}

      <Box className={classes.filtersContainer} flex="3">
        {hasMeilisearch && (
          <QuickSearch
            className={classes.filterSearch}
            margin="none"
            index="orders"
            getOptionLabel={option => `Order #${option.invoice_number}`}
            onChange={order => history.push(Routes.ORDER_DETAILS.replace(":id", order.id))}
            renderOption={(option, _state) => (
              <Grid key={option.id} className={classes.searchResult} container spacing={1}>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Order #{option.invoice_number}</strong> <StatusChip status={option.status} />{" "}
                    <FulfillmentChip fulfillmentType={option.fulfillment_method} />
                  </Typography>
                </Grid>
                <Grid container item spacing={1} xs={12}>
                  <Grid item xs>
                    <Typography color="textSecondary" variant="body2">
                      Placed: {moment.tz(option.created_at, timezone).format("ll")}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography color="textSecondary" variant="body2">
                      Fulfillment: {moment.tz(option.fulfillment_date, timezone).format("ll")}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography color="textSecondary" variant="body2">
                      For: {option.payee_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            searchParams={{
              filter: [`supplier_id = "${supplier.id}"`],
              limit: 10,
            }}
            supplierId={supplier.id}
          />
        )}

        {!hasMeilisearch && (
          <TextField
            InputProps={{
              endAdornment: (
                <Fade in={searchInput.length > 0} unmountOnExit>
                  <IconButton aria-label="search" id="search-submit" onClick={onSearchClear} size="small">
                    <Close />
                  </IconButton>
                </Fade>
              ),
              startAdornment: <SearchIcon className={classes.searchIcon} />,
            }}
            className={classes.filterSearch}
            fullWidth
            label="Search"
            onChange={onSearchInputChange}
            onKeyDown={onSearchSubmit}
            placeholder="Search orders"
            value={searchInput}
            variant="outlined"
          />
        )}
        {hasWholesale && (
          <FormControl className={classes.filterAudience} variant="outlined">
            <InputLabel htmlFor="audience-select">Audience</InputLabel>
            <Select
              defaultValue="ALL"
              inputProps={{
                id: "audience-select",
              }}
              label="Audience"
              onChange={onAudienceChange}
              title="Audience"
              value={audience}
              variant="outlined"
            >
              {Audiences.map(({ id, label }) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl className={classes.filterStatus} variant="outlined">
          <InputLabel htmlFor="status-select">Status</InputLabel>
          <Select
            defaultValue="all"
            inputProps={{
              id: "status-select",
            }}
            label="Status"
            onChange={onStatusChange}
            title="Status"
            value={status}
            variant="outlined"
          >
            {Statuses.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className={classes.filterStart}
          fullWidth
          label="Start date"
          onChange={onDateChange("from")}
          title="from"
          type="date"
          value={from}
          variant="outlined"
        />
        <TextField
          className={classes.filterEnd}
          fullWidth
          inputProps={{ min: from }}
          label="End date"
          onChange={onDateChange("to")}
          type="date"
          value={to}
          variant="outlined"
        />
      </Box>
      <PaginatedTable
        columns={columns}
        defaultContent="No orders"
        loading={loading}
        onPageChange={onPageChange}
        onSortModelChange={onSortChange}
        page={page}
        pageSize={ROW_PER_PAGE}
        rows={rows}
        sortModel={sortModel}
        totalCount={totalCount}
      />

      <ConfirmationModal
        modalTitle="Instructions"
        onCloseModal={() => setOpenInstrcuctionModal(false)}
        shouldOpenModal={openInstrcuctionModal}
        showActionButtons={false}
        showCloseButton
      >
        <Typography>Sync your Freshline orders to Quickbooks Online with just a few steps:</Typography>
        <ol className={classes.instructions}>
          <li>
            After logging into QBO, click Settings {"->"} Import data {"->"} Invoices,{" "}
            <Link href="https://app.qbo.intuit.com/app/dataimport/invoices" rel="noreferrer" target="_blank">
              or click this link (opens in a new tab)
            </Link>
          </li>
          <li>Upload the generated CSV and choose create new contacts/products, if desired</li>
          <li>
            Choose <strong>"D/M/YYYY"</strong> as the secondary option for <strong>Invoice Date</strong>
          </li>
          <li>
            Choose <strong>"Exclusive of tax"</strong> as the secondary option for <strong>Item Amount</strong>
          </li>
          <li>
            Click <strong>Start import</strong>
          </li>
        </ol>
      </ConfirmationModal>
    </>
  );
};

export default OrderList;
